<template>
  <div class="animated fadeIn">
    <b-form @submit.prevent="onSubmit">
      <b-row class="mb-4">
        <b-col cols="12" md="6">
          <b-card title="Marketing Report">
            <div class="clearfix">

              <!-- date pick -->
              <vue-monthly-picker
                class="float-left"
                v-model="reportMonth"
                :max="$moment().subtract(1, 'M')"
              ></vue-monthly-picker>

              <!-- view sources -->
              <!-- router-link class="float-right" :to="{ name: 'invoiceList', query: {
                mo: reptMo,
                yr: reptYr
              } }" target="_blank">View Source Report</router-link -->

            </div>
            <hr />

            <div v-if="reportExists" class="bg-warning">
              <p class="p-4">Please select a month to report using the field above.</p>
            </div>
            <div v-else-if="!sourcesLoaded" class="bg-info">
              <p class="p-4">Just a moment while we gather member registrations...</p>
            </div>
            <div v-else-if="reportSubmitted" class="bg-success">
              <p class="p-4">Submitting your report...</p>
            </div>
            <!-- rept fields -->
            <div v-else>

              <!-- promoting -->
              <b-form-group label="Promoting:">
                <b-form-checkbox-group
                  id="checkbox-group-promoting"
                  v-model="form.promoting"
                  :options="[
                    { text: 'Junior', value: 'junior' },
                    { text: 'Youth', value: 'youth' },
                    { text: 'Adult', value: 'adult' },
                    { text: 'Other', value: 'other' }
                  ]"
                  name="promoting"
                ></b-form-checkbox-group>
              </b-form-group>

              <!-- promoting other -->
              <b-form-input v-if="form.promoting.includes('other')"
                class="mb-2"
                id="promoting-other"
                v-model="form.promotingOther"
                placeholder="What else are you promoting"
              ></b-form-input>

              <!-- sources -->
              <div v-for="(source, index) in form.sources" class="mb-2 border-top" :key="index">
                <b-row class="mb-2">
                  <b-col>
                    <label for="inline-form-input-name">Source</label>
                    <b-form-select 
                      v-model="form.sources[index].vehicle" 
                      :options="marketingSources" 
                      value-field="@id" 
                      text-field="name"
                      required
                    >
                      <template v-slot:first>
                        <option value="" disabled>-- Select --</option>
                      </template>
                    </b-form-select>
                    <b-form-input v-if="form.sources[index].vehicle == otherSrcId"
                      v-model="form.sources[index].addtlLabel" 
                      placeholder="Marketing Source"
                    ></b-form-input>
                  </b-col>
                  <b-col cols="12" md="5">
                    <label for="inline-form-input-name">Cost</label>
                    <b-input-group prepend="$">
                      <b-form-input 
                        type="number" 
                        min="0" 
                        step="0.01" 
                        v-model="form.sources[index].cost" 
                        @change="updateSpendTotal()"
                        required
                      ></b-form-input>
                    </b-input-group>
                  </b-col>
                  <b-col cols="12" md="3">
                    <label for="inline-form-input-name">Signups</label>
                    <b-form-input 
                      type="number" 
                      min="0" 
                      v-model="form.sources[index].conversions" 
                      @change="updateSignupsTotal()"
                      required
                    ></b-form-input>
                  </b-col>
                </b-row>
              </div>
              <b-button variant="success" @click="addNewSource()" block>Add Source</b-button>
            </div>
          </b-card>
        </b-col>
        <b-col>
          <div class="">
            <p>
              Your marketing reports are due on the 1st of each month.
            </p>
            <p>
              Your marketing reports should include all sources where money was spent or member registrations (signups) were generated during 
              the month being reported for. When you have signups during the month that are associated w/ a source which had a spend of $0 for 
              the month being reporeted, the cost should reflect $0. 
            </p>
            <p>
              Automated signup numbers are based on the marketing source selected during the creation of member accounts 
              associated with your N&nbsp;Zone&nbsp;Sports&trade; branch within the month being reported for. 
            </p>
          </div>
          <h4>
            Total Spend: ${{ spendTotal }} <br /> Total Member signups: {{ signupsTotal }}
          </h4>
          <b-form-textarea
          v-model="comments"
          placeholder="Comments"
          class="mb-3"
          ></b-form-textarea>
          <b-btn type="submit" variant="success" block>Submit</b-btn>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import { constants } from '@/shared/constants'
import VueMonthlyPicker from 'vue-monthly-picker'
export default {
  data () {
    return {
      form: {
        promoting: [],
        promotingOther: '',
        sources: [],
        addtlLabel: ''
      },
      otherSrcId: constants.OTHER_SOURCE_ID,
      reportMonth: this.$moment().subtract(1, 'M'),
      spendTotal: 0,
      signupsTotal: 0,
      comments: '',
      reportExists: false,
      sourcesLoaded: false,
      reportSubmitted: false
    }
  },
  computed: {
    ...mapGetters([
      'business',
      'marketingSources'
    ]),
    reptMo () {
      return this.$moment(this.reportMonth._d).format('M')
    },
    reptYr () {
      return this.$moment(this.reportMonth._d).format('YYYY')
    }
  },
  methods: {
    ...mapActions([
      'getUsers',
      'getMarketingSources',
      'getReportsMarketing',
      'postReportsMarketing',
      'postReportMarketingSources'
    ]),
    doGetSources () {
      let query = {
        businesses: this.business.id,
        roles: constants.ROLE_MEMBER,
        pagination: false,
        'created[after]': this.$moment(this.reportMonth._d).format(),
        'created[strictly_before]': this.$moment(this.reportMonth._d).add(1, 'M').format()
      },
      conversions = {}
      return this.getUsers(query).then(resp => {
        for (let i in resp) {
          if (!resp[i].source) {
            continue
          }
          (!(resp[i].source['@id'] in conversions)) 
            ? conversions[resp[i].source['@id']] = 1 
            : conversions[resp[i].source['@id']]++
        }
        for (let id in conversions) {
          this.form.sources.push({
            vehicle: id,
            cost: 0,
            conversions: conversions[id],
            addtlLabel: ''
          })
        }
        this.sourcesLoaded = true
      })
    },
    checkSubmissions () {
      let params = {
        reptMo: this.reptMo,
        reptYr: this.reptYr
      }
      if (this.currentBusiness.id) {
        params.business = this.currentBusiness.id
      }
      return this.getReportsMarketing(params).then(resp => {
        if (resp.length) {
          this.reportExists = true
          this.showError({ message: 'You have already submitted your marketing report for the selected month.' })
          return true
        } else {
          this.reportExists = false
          return false
        }
      })
    },
    setDefaults () {
      this.form = {
        promoting: [],
        promotingOther: '',
        sources: []
      }
      return this.doGetSources().then(() => {
          this.updateSpendTotal()
          this.updateSignupsTotal()
      })
    },
    addNewSource () {
      this.form.sources.push({
        vehicle: "",
        cost: 0,
        conversions: 0,
        addtlLabel: ''
      })
    },
    updateSpendTotal () {
      let spendTotal = 0
      for (let i in this.form.sources) {
        spendTotal += parseFloat(this.form.sources[i].cost)
      }
      this.spendTotal = spendTotal.toFixed(2)
    },
    updateSignupsTotal () {
      let signupsTotal = 0
      for (let i in this.form.sources) {
        signupsTotal += parseInt(this.form.sources[i].conversions)
      }
      this.signupsTotal = signupsTotal
    },
    onSubmit () {
      this.$bvModal.msgBoxConfirm('You are about to submit this marketing report. Once it is submitted, it cannot be modified. '
        +'Please click OK to confrim this action or click Cancel to continue editing.')
      .then(conf => {
        if (conf) {
          this.reportSubmitted = true
          this.postReportsMarketing({
            business: this.business["@id"],
            user: this.currentUser["@id"],
            reptYr: parseInt(this.reptYr),
            reptMo: parseInt(this.reptMo),
            totalSpend: parseFloat(this.spendTotal),
            promoting: this.form.promoting,
            promotingOther: this.form.promotingOther,
            comments: this.comments
          }).then(resp => {
            for (let i in this.form.sources) {
              this.postReportMarketingSources({
                report: resp['@id'],
                cost: parseFloat(this.form.sources[i].cost),
                source: this.form.sources[i].vehicle,
                addtlLabel: this.form.sources[i].addtlLabel,
                conversions: parseInt(this.form.sources[i].conversions),
              })
            }
            this.$router.push({ name: 'reportsMarketingDetails', params: { rptId: resp.id } })
            this.showSuccess({ message: 'Thank you for submitting your report!' })
          })
        }
      })
    }
  },
  watch: {
    reportMonth () {
      this.spendTotal= 0
      this.signupsTotal= 0
      this.reportExists = false
      this.sourcesLoaded = false
      this.reportSubmitted = false
      this.checkSubmissions().then(submitted => {
        if (!submitted) {
          this.setDefaults()
        }
      })
    }
  },
  components: {
    VueMonthlyPicker
  },
  mounted () {
    this.getMarketingSources()
    this.checkSubmissions().then(submitted => {
      if (!submitted) {
        this.setDefaults()
      }
    })
  }
}
</script>
